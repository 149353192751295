<template>
  <observer @on-change="onChange">
    <object-card
        type="icon"
        width="488px"
        height="275px"
        :img="image"
        :title="data.title"
        :category="data.type ? data.type.name : ''"
        :icon="data.type ? data.type.image : ''"
        :item="data"
    />
  </observer>
</template>

<script>
import Observer from 'vue-intersection-observer';
import ObjectCard from "@/components/Parts/ObjectCard";

export default {
  name: "LazyCard",

  props: {
    data: {
      type: [Object, Array],
      default: () => [],
    }
  },

  watch: {
    currentImage(newValue) {
      this.changedImage = newValue;
    }
  },

  computed: {
    currentImage() {
      return this.data.images.length > 0 ? this.data.images[0].replace('extra', 'small') : '';
    }
  },

  data() {
    return {
      changedImage: null,
      image: null,
    }
  },

  components: {
    Observer,
    ObjectCard
  },

  methods: {
    onChange(entry, unobserve) {
      if (entry.isIntersecting) {
        unobserve();
      }

      this.image = entry.isIntersecting ? this.changedImage ? this.changedImage : this.currentImage : '';
    }
  }
}
</script>
